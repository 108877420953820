module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-TH557LG"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/favicon/favicon-32x32.png","icons":[{"src":"src/assets/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"name":"RendPro - Marketing Twojej inwestycji deweloperskiej","short_name":"RendPro","start_url":"/","background_color":"#fff","theme_color":"#F29122","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5bc82532bbc4de49c16fc68249638430"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl","en","de"],"defaultLanguage":"pl","siteUrl":"https://rend.pro","fallbackLanguage":"pl","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1218202625185094"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
